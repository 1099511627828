import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Head from "../components/Head";
import { BlogQueryResult, UNTITLED } from "../graphql";
import Monogram from "../components/Monogram";
import BlogExcerpt from "../components/BlogExcerpt";
import * as classNames from "./BlogArchivePage.module.css";
import { H2 } from "../components/MarkdownElements";
import MarkdownContent from "../components/MarkdownContent";
import Header from "../components/Header";
import { humanReadableDate } from "../util/dates";
import PostMedia from "./PostMedia";

const BlogArchivePage: React.FC<{ query: BlogQueryResult }> = ({ query }) => {
  const [latest, ...previously] = query.allFile.nodes;
  const {
    body,
    excerpt,
    frontmatter: { date, title = UNTITLED, permalink, media },
  } = latest.childMdx;
  let permalinkNode;
  if (permalink) {
    permalinkNode = (
      <Link to={permalink} rel="canonical">
        ∞
      </Link>
    );
  }
  const subtitle = date && (
    <>
      Published {humanReadableDate(date)} {permalinkNode}
    </>
  );
  return (
    <Layout>
      <Head title={title} description={excerpt}>
        <link rel="canonical" href={permalink} />
      </Head>
      {latest && (
        <>
          <article>
            <Header title={title} subtitle={subtitle} />
            {media && <PostMedia media={media} />}
            <MarkdownContent dropcap={true} html={body} />
          </article>
          <Monogram footer={true} />
        </>
      )}
      {previously.length && (
        <MarkdownContent brief={true}>
          <H2>Previously…</H2>
          {previously.map((post) => (
            <BlogExcerpt post={post} key={post.id} />
          ))}
          <p className={classNames.complete}>
            <Link to="/archive/2">Complete archive &raquo;</Link>
          </p>
        </MarkdownContent>
      )}
    </Layout>
  );
};

export default BlogArchivePage;
