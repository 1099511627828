import React, { FC } from "react";
import { BlogQueryResultItem } from "../graphql";
import { humanReadableDate } from "../util/dates";
import MediaEntry from "./MediaEntry";

export type BlogExcerptProps = {
  post: BlogQueryResultItem;
};

/**
 * Excerpt to a blog post
 */
const BlogExcerpt: FC<BlogExcerptProps> = ({ post }) => {
  const {
    excerpt = "",
    frontmatter: { title = "", permalink, date = "" },
  } = post.childMdx;
  return (
    <MediaEntry
      key={post.id}
      title={title}
      url={permalink}
      credit={`Published ${humanReadableDate(date)}`}
    >
      {excerpt}
    </MediaEntry>
  );
};

export default BlogExcerpt;
