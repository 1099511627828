// This file exists because in a Node context TS can't compile CSS modules,
// so we put type information shared between Node and the browser here
// rather than in component files.

import { Snippet, Post, BlogQueryResultItem, MDXFile, Page } from "../graphql";

export const DISCLAIMER_SNIPPET = "pre-transition-disclaimer";

export interface PostContext {
  disclaimer: Snippet;
  post: Post;
  previous?: Post;
  next?: Post;
}

export const ARCHIVE_SNIPPET = "archive";

export interface ArchivePageContext {
  intro: Snippet;
  posts: BlogQueryResultItem[];
  numPages: number;
  currentPage: number;
}

export type ContentPageContext = {
  page: MDXFile<Page>;
};

export const MediaTypes = {
  MP3: "audio/mpeg",
} as const;
export type MediaType = typeof MediaTypes[keyof typeof MediaTypes];
