import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { BlogQueryResult } from "../graphql";
import BlogArchivePage from "../components/BlogArchivePage";

const BlogArchivePageHOC: React.FC<{}> = () => {
  const query = useStaticQuery<BlogQueryResult>(graphql`
    query {
      allFile(
        filter: { sourceInstanceName: { eq: "posts" } }
        limit: 6
        sort: { fields: childMdx___frontmatter___date, order: DESC }
      ) {
        nodes {
          id
          childMdx {
            frontmatter {
              author
              slug
              permalink
              title
              date
            }
            excerpt
            body
          }
        }
      }
    }
  `);
  return <BlogArchivePage query={query} />;
};

export default BlogArchivePageHOC;
