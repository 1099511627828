import React, { FC, HTMLAttributes, useState, MouseEventHandler } from "react";
import { Media } from "../graphql";
import { MediaTypes } from "../util/page-types";
import cx from "classnames";
import * as classNames from "./PostMedia.module.css";
import useHtmlId from "../util/use-html-id";
import Figure from "./Figure";

export type PostMediaProps = { media: Media } & HTMLAttributes<HTMLElement>;

function zeroPad(n: number): string {
  return n < 10 ? `0${n}` : n.toString(10);
}

const MINUTE = 60;
const HOUR = 60 * 60;

/**
 * Returns a formatted time string
 * @param t duration in seconds (should be a positive integer)
 */
export function getDuration(t: number): string {
  const time = Math.floor(t);
  const seconds = time % MINUTE;
  const hours = Math.floor(time / HOUR);
  const minutes = Math.floor((time - hours * HOUR) / MINUTE);
  return hours
    ? `${hours}:${zeroPad(minutes)}:${zeroPad(seconds)}`
    : `${minutes}:${zeroPad(seconds)}`;
}

const BYTE = 1024;
const magnitudes = ["", "k", "M", "G", "T", "P", "E"] as const;

/**
 * Returns a formated size string
 * @param bytes Size in bytes
 */
export function getSize(bytes: number): string {
  const b = Math.floor(bytes);
  if (b < 0) {
    return `0b`;
  }
  let value = b;
  let i = 0;
  while (value >= BYTE && i < magnitudes.length - 1) {
    value /= BYTE;
    i += 1;
  }
  return `${Math.floor(value).toString(10)}${magnitudes[i]}b`;
}

export const PostAudio: FC<PostMediaProps> = ({
  className,
  media,
  ...htmlProps
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const id = useHtmlId();
  const handleOnClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setIsInitialized(true);
  };
  const duration = getDuration(media.duration);
  const size = getSize(media.size);
  return (
    <Figure
      className={cx(classNames.root, className)}
      caption={`${media.title} (audio, ${duration}, ${size})`}
      captionId={id}
      {...htmlProps}
    >
      <div className={classNames.audio}>
        <img className={classNames.audioImage} src={media.image} alt="" />
        {isInitialized ? (
          <audio
            className={classNames.controls}
            controls
            autoPlay
            preload="metadata"
          >
            <source src={media.url} type={media.type} />
          </audio>
        ) : (
          <svg
            viewBox="0 0 320 320"
            className={classNames.playButton}
            onClick={handleOnClick}
            role="button"
            aria-labelledby={id}
          >
            <circle
              className={classNames.audioPlayCircle}
              fill="#FFFFFFCC"
              cx="160"
              cy="160"
              r="80"
            ></circle>
            <polygon
              className={classNames.audioPlayArrow}
              points="140,120 140,200 200,160"
            />
          </svg>
        )}
      </div>
    </Figure>
  );
};

function mediaByType(props: PostMediaProps) {
  switch (props.media.type) {
    case MediaTypes.MP3:
      return <PostAudio {...props} />;
    default:
      return null;
  }
}

const PostMedia: FC<PostMediaProps> = (props) => mediaByType(props);

export default PostMedia;
